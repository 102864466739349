(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/draw-manager/assets/javascripts/draw-manager.js') >= 0) return;  svs.modules.push('/components/tipsen/draw-manager/assets/javascripts/draw-manager.js');

'use strict';

const {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} = React;
const {
  useSelector,
  useDispatch
} = ReactRedux;
const {
  getDisplayName
} = svs.utils.products;
const {
  analytics
} = svs.components;
const {
  composeDrawWithFunds,
  useHandleSortDraws,
  useFilterDraws
} = svs.components.tipsen.drawManager.utils;
const {
  useMediaQuery,
  breakpoints,
  BREAKPOINT_MD
} = svs.components.lbUtils.useMediaQuery;
const {
  selectCurrentDrawsByProduct
} = svs.components.tipsen.selectors;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useBranding,
  useLockedDraw
} = svs.components.tipsen.hooks;
const {
  DrawManagerContent,
  DrawManagerModal
} = svs.components.tipsen.drawManager;
const {
  loadNetSaleForDraws
} = svs.components.sport.drawsRedux.actions;
const {
  selectAllFunds
} = svs.components.sport.drawsRedux.selectors;
const TEN_SECONDS_CACHE = 10000;
const DrawManager = () => {
  const isMediumDevice = useMediaQuery(breakpoints.up(BREAKPOINT_MD));
  const [isDrawSelectorVisible, setIsDrawSelectorVisible] = useState(false);
  const {
    drawNumber: currentDrawNumber
  } = useCurrentDraw();
  const [productBranding] = useBranding();
  const draws = useSelector(selectCurrentDrawsByProduct);
  const dispatch = useDispatch();
  const lastNetSaleFetchTimestamp = useRef();
  const lastDrawNumber = useRef(currentDrawNumber);
  const isLockedDraw = useLockedDraw();
  const allFunds = useSelector(selectAllFunds);
  const drawsWithExtraMoney = composeDrawWithFunds(draws, allFunds);
  const filteredDraws = useFilterDraws(drawsWithExtraMoney);
  const sortedDrawsData = useHandleSortDraws(filteredDraws);
  const hasExtraMoneyValue = useMemo(() => sortedDrawsData.some(draw => Object.keys(draw.extraMoney).length > 0), [sortedDrawsData]);
  const sportTypes = useMemo(() => {
    const sports = new Set();
    drawsWithExtraMoney.forEach(draw => {
      sports.add(draw.sportType);
    });
    return [...sports];
  }, [drawsWithExtraMoney]);
  const currentDraw = drawsWithExtraMoney.length > 0 ? drawsWithExtraMoney.filter(_ref => {
    let {
      drawNumber
    } = _ref;
    return drawNumber === currentDrawNumber;
  })[0] : {};
  const shouldEnableDrawSelector = draws.length > 1 && !isLockedDraw;
  const shouldShowDrawManagerModal = !isMediumDevice && shouldEnableDrawSelector && isDrawSelectorVisible;
  const toggleDrawSelector = useCallback(async shouldBeVisible => {
    if (typeof shouldBeVisible === 'boolean') {
      setIsDrawSelectorVisible(shouldBeVisible);
    } else {
      setIsDrawSelectorVisible(current => !current);
    }
  }, []);
  useEffect(() => {
    if (!isDrawSelectorVisible && currentDrawNumber !== lastDrawNumber.current) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
    if (isDrawSelectorVisible) {
      const currentTimestamp = Date.now();
      const shouldFetchNetSales = !lastNetSaleFetchTimestamp.current || currentTimestamp - lastNetSaleFetchTimestamp.current > TEN_SECONDS_CACHE;
      analytics.trackEvent({
        category: "Nya tipsen",
        action: 'change_draw_button_clicked',
        opt_label: getDisplayName(currentDraw.productId)
      });
      if (shouldFetchNetSales) {
        dispatch(loadNetSaleForDraws({
          productId: currentDraw.productId
        }));
        lastNetSaleFetchTimestamp.current = Date.now();
      }
    }
    lastDrawNumber.current = currentDrawNumber;
  }, [currentDraw.productId, currentDrawNumber, dispatch, isDrawSelectorVisible]);
  if (!currentDraw) {
    return null;
  }
  if (shouldShowDrawManagerModal) {
    return React.createElement(DrawManagerModal, {
      currentDrawNumber: currentDrawNumber,
      draws: sortedDrawsData,
      hasExtraMoneyValue: hasExtraMoneyValue,
      isOpen: isDrawSelectorVisible,
      productBranding: productBranding,
      sportTypes: sportTypes,
      toggleDrawSelector: toggleDrawSelector
    });
  }
  return React.createElement(DrawManagerContent, {
    currentDraw: currentDraw,
    draws: sortedDrawsData,
    hasExtraMoneyValue: hasExtraMoneyValue,
    isDrawSelectorVisible: isDrawSelectorVisible,
    isMediumDevice: isMediumDevice,
    productBranding: productBranding,
    shouldEnableDrawSelector: shouldEnableDrawSelector,
    sportTypes: sportTypes,
    toggleDrawSelector: toggleDrawSelector
  });
};
setGlobal('svs.components.tipsen.drawManager.DrawManager', DrawManager);

 })(window);